var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('div',{staticClass:"admin_main_block"},[_c('div',{staticClass:"admin_breadcrumb"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/Admin/index' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("粉丝管理")]),_c('el-breadcrumb-item',[_vm._v("会员列表")]),_c('el-breadcrumb-item',[_vm._v("新增会员")])],1)],1)]),_c('el-form',{ref:"form",attrs:{"model":_vm.form}},[_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-row',{attrs:{"gutter":40}},[_c('el-col',{attrs:{"span":24}},[_c('span',{staticClass:"grid-divider"},[_vm._v("基本信息")]),_c('el-divider')],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"会员ID","prop":"realName","rules":[
              { required: true, message: '会员ID', trigger: 'blur' },
              {
                min: 2,
                max: 20,
                message: '长度在 2 到 20 个字符',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"placeholder":"","disabled":true},model:{value:(_vm.form.realName),callback:function ($$v) {_vm.$set(_vm.form, "realName", $$v)},expression:"form.realName"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"等级","prop":"idCardNo","rules":[
              { required: true, message: '请输入等级', trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"","disabled":_vm.disabled},model:{value:(_vm.form.idCardNo),callback:function ($$v) {_vm.$set(_vm.form, "idCardNo", $$v)},expression:"form.idCardNo"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"昵称","prop":"account","rules":[
              { required: true, message: '请输入昵称', trigger: 'blur' },
              {
                min: 1,
                max: 50,
                message: '长度在 1 到 50 个字符',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"placeholder":"","disabled":_vm.disabled},model:{value:(_vm.form.account),callback:function ($$v) {_vm.$set(_vm.form, "account", $$v)},expression:"form.account"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"性别","prop":"enable","rules":[
              { required: true, message: '请选择性别', trigger: 'blur' } ]}},[_c('el-select',{attrs:{"placeholder":"请选择","disabled":_vm.disabled,"clearable":""},model:{value:(_vm.form.enable),callback:function ($$v) {_vm.$set(_vm.form, "enable", $$v)},expression:"form.enable"}},[_c('el-option',{attrs:{"label":"男","value":"true"}}),_c('el-option',{attrs:{"label":"女","value":"false"}})],1)],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"生日","prop":"account","rules":[
              { required: true, message: '请输入生日', trigger: 'blur' },
              {
                min: 1,
                max: 50,
                message: '长度在 1 到 50 个字符',
                trigger: 'blur',
              } ]}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","clearable":"","default-value":"1990-10-10","placeholder":"选择日期"},model:{value:(_vm.form.breth),callback:function ($$v) {_vm.$set(_vm.form, "breth", $$v)},expression:"form.breth"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"地区","prop":"operationArea","rules":[
              { required: true, message: '请选择地区', trigger: 'blur' } ]}},[_c('all-area-list',{attrs:{"show":_vm.disabled},on:{"get-all-area-list":_vm.getAllAreaList},model:{value:(_vm.form.officeArea),callback:function ($$v) {_vm.$set(_vm.form, "officeArea", $$v)},expression:"form.officeArea"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"手机号","prop":"account","rules":[
              { required: true, message: '请输入手机号', trigger: 'blur' },
              {
                min: 6,
                max: 20,
                message: '长度在 6 到 20 个字符',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"placeholder":""},model:{value:(_vm.form.realName),callback:function ($$v) {_vm.$set(_vm.form, "realName", $$v)},expression:"form.realName"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"绑定微信"}},[_c('el-input',{attrs:{"placeholder":""},model:{value:(_vm.form.realName),callback:function ($$v) {_vm.$set(_vm.form, "realName", $$v)},expression:"form.realName"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"绑定支付宝"}},[_c('el-input',{attrs:{"placeholder":""},model:{value:(_vm.form.realName),callback:function ($$v) {_vm.$set(_vm.form, "realName", $$v)},expression:"form.realName"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"状态","prop":"enable","rules":[
              { required: true, message: '请选择状态', trigger: 'blur' } ]}},[_c('el-select',{attrs:{"placeholder":"请选择","disabled":_vm.disabled,"clearable":""},model:{value:(_vm.form.enable),callback:function ($$v) {_vm.$set(_vm.form, "enable", $$v)},expression:"form.enable"}},[_c('el-option',{attrs:{"label":"开启","value":"true"}}),_c('el-option',{attrs:{"label":"禁用","value":"false"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-button',{attrs:{"icon":"el-icon-check","type":"primary"},on:{"click":_vm.back}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"icon":"el-icon-back","type":"primary"},on:{"click":function($event){return _vm.success('form')}}},[_vm._v(" 提交 ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }