<template>
  <div class="app">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>粉丝管理</el-breadcrumb-item>
          <el-breadcrumb-item>会员列表</el-breadcrumb-item>
          <el-breadcrumb-item>新增会员</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <el-form ref="form" :model="form">
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="24">
            <span class="grid-divider">基本信息</span>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="会员ID"
              prop="realName"
              :rules="[
                { required: true, message: '会员ID', trigger: 'blur' },
                {
                  min: 2,
                  max: 20,
                  message: '长度在 2 到 20 个字符',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                placeholder=""
                v-model="form.realName"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="等级"
              prop="idCardNo"
              :rules="[
                { required: true, message: '请输入等级', trigger: 'blur' },
              ]"
            >
              <el-input
                placeholder=""
                v-model="form.idCardNo"
                :disabled="disabled"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="昵称"
              prop="account"
              :rules="[
                { required: true, message: '请输入昵称', trigger: 'blur' },
                {
                  min: 1,
                  max: 50,
                  message: '长度在 1 到 50 个字符',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                placeholder=""
                v-model="form.account"
                :disabled="disabled"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="性别"
              prop="enable"
              :rules="[
                { required: true, message: '请选择性别', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="form.enable"
                placeholder="请选择"
                :disabled="disabled"
                clearable
              >
                <el-option label="男" value="true"></el-option>
                <el-option label="女" value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="生日"
              prop="account"
              :rules="[
                { required: true, message: '请输入生日', trigger: 'blur' },
                {
                  min: 1,
                  max: 50,
                  message: '长度在 1 到 50 个字符',
                  trigger: 'blur',
                },
              ]"
            >
              <el-date-picker
                v-model="form.breth"
                type="date"
                style="width: 100%"
                clearable
                default-value="1990-10-10"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="地区"
              prop="operationArea"
              :rules="[
                { required: true, message: '请选择地区', trigger: 'blur' },
              ]"
            >
              <all-area-list
                v-model="form.officeArea"
                @get-all-area-list="getAllAreaList"
                :show="disabled"
              ></all-area-list>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="手机号"
              prop="account"
              :rules="[
                { required: true, message: '请输入手机号', trigger: 'blur' },
                {
                  min: 6,
                  max: 20,
                  message: '长度在 6 到 20 个字符',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input placeholder="" v-model="form.realName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item label="绑定微信">
              <el-input placeholder="" v-model="form.realName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item label="绑定支付宝">
              <el-input placeholder="" v-model="form.realName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="状态"
              prop="enable"
              :rules="[
                { required: true, message: '请选择状态', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="form.enable"
                placeholder="请选择"
                :disabled="disabled"
                clearable
              >
                <el-option label="开启" value="true"></el-option>
                <el-option label="禁用" value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="admin_main_block admin_m15">
        <el-row>
          <el-col :span="24">
            <el-button icon="el-icon-check" type="primary" @click="back">
              取消
            </el-button>
            <el-button
              icon="el-icon-back"
              type="primary"
              @click="success('form')"
            >
              提交
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>
<script>
import allAreaList from "@/components/admin/allAreaList.vue";

export default {
  components: {
    allAreaList,
  },
  props: {},
  data() {
    return {
      infoDetail: {},
      span: 6,
      disabled: false,
      actionUploadUrl: "",
      listOperPopData: [],
      listServPopData: [],
      isEdit: true,
      form: {},
    };
  },
  methods: {
    infoData() {
      // let id = this.$route.query.id;
      // this.$get(this.$api.bdDetail, {id: id}).then((res) => {
      // })
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
    success(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.post(this.$api.bdAdd, this.form);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          this.$message({
            type: "warning",
            message: "请填写完整信息！",
          });
          return false;
        }
      });
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          location.reload();
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    getAllAreaList(data) {
      const val = data["code"];
      const name = data["name"];
      if (val && val.length > 0 && Array.isArray(val)) {
        this.form.officeArea = name.join("/");
      }
    },
  },
  created() {
    this.infoData();
  },
};
</script>
<style lang="scss" scoped>
.is-always-shadow {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.is-always-shadow .el-col {
  height: auto;
}

.el-card {
  background: #F2F6FC;
  text-align: right;
  margin-top: 30px;
}

.el-row {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.hight {
  height: 90px;
}

.el-col {
  border-radius: 4px;
}

.el-tab-pane {
  padding-top: 10px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-divider--horizontal {
  margin: 12px 0px 12px 0px;
}

.sub-title {
  min-height: 26px;
}

.el-imag {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 178px;
}

.el-imag:hover {
  border-color: #409EFF;
}

.el-icon-picture-outline {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.orderStatus {
  font-size: large;
  color: #409EFF;
}

.right {
  text-align: center;
  line-height: 30px;
}

.admin_table_main_pagination {
  line-height: 40px;
  text-align: right;
}

.admin_table_main_pagination .total {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.admin_table_main_pagination .number {
  font-size: 18px;
  font-weight: bold;
  color: #ff0000;
}

.el-select {
  width: 100%;
}
</style>

